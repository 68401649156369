<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mr-2">
          <v-card-title class="subheading">Invoices</v-card-title>
          <v-card-text v-if="isProgram">
            <v-row>
              <v-col cols="12">
                <v-select
                  @change="getItems()"
                  v-model="dataKey"
                  :items="keys"
                  attach
                  label="Data set"
                  v-validate="'required'"
                  data-vv-as="dataKey"
                  name="dataKey"
                  :error-messages="errors.collect('dataKey')"
                ></v-select>
              </v-col>
              <v-col class="pr-4" cols="6">
                <v-text-field
                  v-model="vm.item"
                  label="Item"
                  v-validate="'required'"
                  data-vv-as="item"
                  name="item"
                  :error-messages="errors.collect('item')"
                ></v-text-field>
                <v-spacer></v-spacer>
                <div></div>
              </v-col>
              <v-col cols="6">
                <v-btn @click="addItem()">Add</v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  id="list-table"
                  v-bind:headers="[
                { text: 'ID', value: 'id' },
                { text: 'key', value: 'key' },
                { text: 'item', value: 'item' }
              ]"
                  :items="items"
                  item-key="id"
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td text-xs-left>{{props.item.id}}</td>
                      <td text-xs-left>{{props.item.key}}</td>
                      <td text-xs-left>{{props.item.item}}</td>

                      <td>
                        <v-icon small @click="deleteItem(props.item)">mdi-trash-can-outline</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

                    <v-card-text> 
                      <v-row>
                        <v-col class="pr-4" cols="6">
                          <v-text-field
                          v-model="invoiceNumber"
                          label="Invoice Number"
                          v-validate="'required'"
                          name="invoiceNumber"
                          data-vv-as="invoiceNumber"
                          :error-messages="errors.collect('invoiceNUmber')"
                          ></v-text-field>
                        <v-spacer></v-spacer>
                          <div></div>
                        </v-col>
                      <v-col cols="6">
                        <v-btn @click="addInvoice()">Add Invoice</v-btn>
                      </v-col> 
                      
                      <v-col >
                        <p >Last invoice number is: {{ invoiceNumber }}</p> 
                      </v-col>
                       <v-col cols="8">
                       <v-icon size="18" color="red" @click="deleteInvoice()">mdi-trash-can-outline</v-icon>
                        </v-col>
                      
                     

                      </v-row> 
                      </v-card-text>     

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as refDataService from "../../services/referenceDataService";
//import * as migrationService from "../../services/migrationService";
import * as invoiceService from "../../services/invoiceService";
Vue.use(VeeValidate);

export default {
  data: () => ({
    keys: ["programs"],
    items: [],
    dataKey: "",
    vm: {},
    isProgram: false,
    show: true,
    invoiceNumber: "", 
    invoices: [],   
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {this.getInvoices();},
    getItems() {
      var me = this;
      if (me.dataKey) {
        refDataService
          .listByKey(me.currentOrganisation.id, me.dataKey)
          .then(results => {
            results.docs.map(doc => {
              let item = doc.data();
              item.id = doc.id;
              me.items.push(item);
            });
          });
      }
    },
    async getInvoices(){
        var me=this;
    
       let invoiceRef = invoiceService.getInvoiceRef(me.currentOrganisation.id);
            const snapshot = await invoiceRef.get();

            snapshot.forEach((doc) => {
              let invoice = doc.data();
              invoice.id = doc.id;
              me.invoices.push(invoice);
            });

            me.invoiceNumber = me.invoices[me.invoices.length - 1].id;
            console.log(me.invoices)
            
          
    },
    addInvoice(){
      let me = this;
        invoiceService.addInvoice(me.currentOrganisation.id, me.invoiceNumber).then((result => {
              
                console.log(`Item added to invoices ${result}`);  
                 me.invoices.push({"id": me.invoiceNumber});
                 me.$store.dispatch("setToast", {
                 message: "Invoice added",
                 color: "primary"
                });
                console.log(me.invoices);
              }));
    },
    deleteInvoice(){
      let me=this;

      if(me.invoices.find(o => o.id === me.invoiceNumber))
      {
      invoiceService.removeInvoice(me.currentOrganisation.id, me.invoiceNumber).then((result => {
        console.log(`Item deleted from invoices ${result}`);
           me.invoices.splice(me.invoices.indexOf(me.invoiceNumber), 1);
          me.invoiceNumber = me.invoices[me.invoices.length - 1].id;
          me.$store.dispatch("setToast", {
          message: "Invoice deleted",
          color: "red"
        });
      }));
      }else{
        me.$store.dispatch("setToast", {
          message: "Invoice not found",
          color: "red"
        });
        me.invoiceNumber = me.invoices[me.invoices.length - 1].id;
      }


    },
    addItem() {
      let me = this;
      me.vm.key = me.dataKey;
      if (me.dataKey) {
        refDataService.save(me.currentOrganisation.id, me.vm).then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
            me.items.push(me.vm);
            me.vm = {};
          }
          me.$store.dispatch("setToast", {
            message: "Item added",
            color: "primary"
          });
        });
      }
    },
    deleteItem(data) {
      let me = this;
      refDataService.remove(me.currentOrganisation.id, data.id).then(() => {
        me.items.splice(me.items.indexOf(data), 1);
        me.$store.dispatch("setToast", {
          message: "Item deleted",
          color: "warning"
        });
      });
    }
  }
};
</script>
<style>

</style>