import Vue from "vue";

function getRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("invoices");
}

export async function getById(orgId, id) {
    return getRef(orgId)
      .doc(id)
      .get();
  }
export async function list(orgId) {
    return getRef(orgId).get();
  }
export function getInvoiceRef(orgId){
    return getRef(orgId)
}  
export async function addInvoice(orgId ,invoiceId)
{
  const data={}
  return getInvoiceRef(orgId).doc(invoiceId).set(data);
  
}
export async function removeInvoice(orgId, id) {
    return getRef(orgId)
      .doc(id)
      .delete();
  }